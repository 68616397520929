import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import MobileMenu from "./MobileMenu";

import { getSocialLinks } from "./utils";
import hoverLogo from "~/images/logo/Logo_Hover_on_Color.svg";
import './Header.css';

const SCROLL_THRESHOLD = 120;

const Header: React.FC = () => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= SCROLL_THRESHOLD) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={cx(
        "fixed z-50 w-full lg:relative lg:w-auto headerMenu",
        isScrolled && "bg-dark-500 shadow-sm"
      )}
    >
      <header className="container mx-auto flex justify-between items-center py-4 transition-colors duration-300">
        <div className="hidden lg:flex items-center">
          <img src={hoverLogo} alt="hoverLogo" className="h-4 lg:h-8"/>
        </div>
        <div className="flex items-center lg:gap-x-3">
          <div className="mt-20 flex justify-between items-center lg:mt-0 lg:gap-x-6 hidden lg:block">
            {getSocialLinks(t,'color').map(({ icon, alt, href }) => (
              <a href={href} key={alt} target="_blank">
                <img src={icon} alt={alt} className="w-5 inline-block" />
                <p className="inline-block p-2 pr-4 text-gray-50">{alt}</p>
              </a>
            ))}
          </div>
        </div>
        <MobileMenu />
      </header>
    </div>
  );
};

export default Header;
