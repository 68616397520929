import { graphql } from "gatsby";
import React from "react";
import Hero from "~/index-page/Hero";
import LandingLayout from "~/landing/LandingLayout";

const IndexPage: React.FC = () => (
  <LandingLayout>
     <Hero />
  </LandingLayout>
  
);

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
