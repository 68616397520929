import { TFunction } from "i18next";

import docIcon from "~/images/icons/doc.svg";
import docIconWhite from "~/images/icons/doc_on_white.svg";
import twitterIcon from "~/images/social-icons/twitter.svg";
import twitterIconWhite from "~/images/social-icons/twitter_on_white.svg";
import telegramIcon from "~/images/social-icons/telegram.svg";
import telegramIconWhite from "~/images/social-icons/telegram_on_white.svg";
import discordIcon from "~/images/social-icons/discord.svg";
import discordIconWhite from "~/images/social-icons/discord_on_white.svg";

export function getSocialLinks(t: TFunction,color: string) {
  return [
    {
      icon: color == 'color' ? docIcon : docIconWhite,
      alt: "Docs",
      href: "https://docs.hover.fi",
    },
    {
      icon: color == 'color' ? twitterIcon : twitterIconWhite,
      alt: "Twitter",
      href: "https://twitter.com/Hover_Finance",
    },
    { 
      icon: color == 'color' ? telegramIcon : telegramIconWhite,
      alt: "Telegram", 
      href: "https://t.me/HoverFinance" 
    },
    { 
      icon: color == 'color' ? discordIcon : discordIconWhite,
      alt: "Discord",
      href: "https://discord.com/invite/J36rJupxSC" 
    },
  ];
}
