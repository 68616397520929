import React, { useState } from "react";
import cx from "classnames";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import hoverLogoWhite from "~/images/logo/Logo_Hover_on_White.svg";
import hoverLogoColor from "~/images/logo/Logo_Hover_on_Color.svg";
import hamburgerIcon from "~/images/icons/hamburger.svg";
import crossIcon from "~/images/icons/cross.svg";
import { getSocialLinks } from "./utils";

const MobileMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="lg:hidden w-full flex justify-between">
      <img src={hoverLogoColor} alt="hoverLogoColor" className={open ? "hidden" : "flex items-center h-4 block z-50"}/>
      <img src={hoverLogoWhite} alt="hoverLogoWhite" className={!open ? "hidden" : "flex items-center h-4 block z-50"}/>
      <button type="button" className="z-50" onClick={toggle}>
        <img src={hamburgerIcon} alt="hamburger" className={open ? "hidden" : "flex items-center"} />
        <img src={crossIcon} alt="hamburger" className={!open ? "hidden" : "flex items-center"} />
      </button>
      <div
        className={cx(
          "fixed z-40 pt-16 bg-primary inset-0 transform-gpu transition-transform duration-300",
          open ? "translate-x-0" : "translate-x-full"
        )}
      >
        <div className="container mx-auto">
          {getSocialLinks(t,'white').map(({ icon, alt, href }) => (
              <a href={href} key={alt} target="_blank"className="block w-full py-2 mb-2 ">
                <img src={icon} alt={alt} className="w-5 inline-block" />
                <p className="inline-block p-2 text-white text-lg">{alt}</p>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
