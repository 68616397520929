import { Link } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import Button from "~/ui/Button";

import hoverLogo from "~/images/logo/Logo_Hover_on_White.svg";
import hoverLogoFull from "~/images/logo/Logo_Hover_Full.svg";


type IFarmSlide = {
  key: string;
  pair: string;
  farmToken: string;
  baseToken: string;
  origin: string;
  apy: number;
  openPositionUrl: string;
};

const Hero: React.FC = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  

  let slides: Array<IFarmSlide> = [];


  return (
    <section className="relative overflow-hidden pt-44 pb-4 lg:pt-60 lg:overflow-visible">
      <div className="container lg:mx-auto lg:flex lg:justify-center">
        <div className="relative z-10 lg:w-2/3">
          <img src={hoverLogoFull} alt="hover logo" className="h-20 lg:h-48 container justify-center"/>
          <p className="hidden lg:block mt-12 font-thin text-lg w-2/3 mx-auto">
            <div className="flex justify-center">
            Secure, easy-to-use Institutional DeFi Products
            </div>
            <div className="flex justify-center">
            that maximize risk-adjusted returns
            </div>
          </p>
          <p className="block lg:hidden mt-12 font-thin text-xs w-2/3 mx-auto">
            <div className="flex justify-center">
            Secure, easy-to-use
            </div>
            <div className="flex justify-center">
            Institutional DeFi Products
            </div>
            <div className="flex justify-center">
            that maximize risk-adjusted returns
            </div>
          </p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhimJ5Ym2CAO-N3H_9qIwoGJvQF8YLTCr64jOySGh_XDutQg/viewform" className="mt-8 lg:mt-12 block flex justify-center">
            <Button className="rounded-3xl lg:text-lg px-8 lg:py-2 lg:px-16">{t("Join Waitlist")}</Button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
