import React from 'react';
import cx from 'classnames';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  appearance?: 'primary' | 'secondary';
}

const Button: React.FC<Props> = ({
  appearance = 'primary',
  className,
  children,
  type,
  ...props
}) => (
  <button
    className={cx(
      'border rounded-lg text-xs font-bold leading-7',
      {
        'bg-primary text-white': appearance === 'primary',
        'border-white text-white': appearance === 'secondary',
        'px-5': !className?.match(/\b(p|px|py|pt|pr|pb|pl)-/),
      },
      className,
    )}
    // eslint-disable-next-line react/button-has-type
    type={type || 'button'}
    {...props}
  >
    {children}
  </button>
);

export default Button;
