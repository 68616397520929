import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Header from './Header';
import './LandingLayout.css';

interface Props {
  title?: string;
}

const LandingLayout: React.FC<Props> = ({ title, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className='bg-hero-mobile lg:bg-hero-desktop h-screen w-screen bg-no-repeat bg-cover'>
      <Helmet>
        {title ? (
          <title>
            {data.site.siteMetadata.title} | {title}
          </title>
        ) : (
          <title>{data.site.siteMetadata.title}</title>
        )}
      </Helmet>
      <Header />
      <div className="pt-16 lg:pt-0">{children}</div>
    </div>
  );
};

export default LandingLayout;
